import React, { useState, useEffect }  from 'react'
import PropTypes from 'prop-types'
import {
  AppContextProvider,
  ThemeProvider,
  aefTheme,
  afiTheme,
  prestasumaTheme,
  crediconstruyeTheme,
  prestaunicoTheme,
  findepTheme,
  fisaPrimeraChambaTheme,
  fisaBisTheme as fisaTheme,
} from "@findep/microfronts-core"
import loadable from '@loadable/component'
import { CssBaseline } from '@material-ui/core'
import whatsappExclude from '../data/whatsappExclude.json'

const Whatsapp = loadable(() => import("../components/Whatsapp"))

function ProvidersConfigLayout({ companyName, children, appContext }) {
  const [isPrimeraChamba, setIsPrimeraChamba] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsPrimeraChamba(window.location.pathname.includes("primera-chamba"));
    }
  }, []);

  const getTheme = (company) => {
    let themeReturn
    if (company === 'FISA') {
      if (isPrimeraChamba) {
        themeReturn = fisaPrimeraChambaTheme
      } else {
        themeReturn = fisaTheme
      }
    } else if (company === 'AFI') {
      themeReturn = afiTheme
    } else if (company === 'AEF' || company === 'AEF_APP') {
      themeReturn = aefTheme
    } else if (company === 'PRESTASUMA') {
      themeReturn = prestasumaTheme
    } else if (company === 'CREDICONSTRUYE') {
      themeReturn = crediconstruyeTheme
    } else if (company === 'PRESTAUNICO') {
      themeReturn = prestaunicoTheme
    } else if (company === 'FINDEP') {
      themeReturn = findepTheme
    } else {
      themeReturn = fisaTheme
    }
    return themeReturn
  }
  const whatsappItem = whatsappExclude.find(item => item.toUpperCase() === companyName?.toUpperCase())


  return (
    <AppContextProvider appContext={{ ...appContext, companyName }} >
      <ThemeProvider theme={getTheme(companyName)}>
        <CssBaseline />
        {children}
        {Boolean(whatsappItem) ? '' : <Whatsapp appContext={{ ...appContext, companyName }} />}
      </ThemeProvider>
    </AppContextProvider >
  )
}

ProvidersConfigLayout.propTypes = {
  companyName: PropTypes.oneOf([
    'AEF',
    'AFI',
    'FISA',
    'PRESTASUMA',
    'CREDICONSTRUYE',
    'PRESTAUNICO',
    'FINDEP'
  ]).isRequired,
  children: PropTypes.node.isRequired,
  appContext: PropTypes.object.isRequired,
}

export default ProvidersConfigLayout